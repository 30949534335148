<template>
  <div class="admin">

    <div class="admin-area">

      <div class="row row-gutter-20">
        <div class="col-6">
          <admin-nav></admin-nav>
        </div>
        <div class="col-18">

          <header class="admin-header cf">
            <ul class="nav nav-header cf">
              <li><router-link to="/admin/vehicle_categories">Kategorien</router-link></li>
              <li><router-link :to="'/admin/vehicle_categories/'+this.$route.params.id">{{ vehicle_category.name }}</router-link></li>
              <li>Extras</li>
            </ul>
            <div class="header-actions">
            </div>
          </header>

          <ul class="nav nav-tabs cf">
            <li><router-link :to="'/admin/vehicle_categories/'+this.$route.params.id">Stammdaten</router-link></li>
            <li><router-link :to="'/admin/vehicle_categories/'+this.$route.params.id+'/prices'">Preise</router-link></li>
            <li class="active"><router-link :to="'/admin/vehicle_categories/'+this.$route.params.id+'/extras'">Extras</router-link></li>
          </ul>

          <div class="admin-body">

            <table class="table">
              <thead>
                <tr>
                  <th width="5%"></th>
                  <th width="95%">Name</th>
                </tr>
              </thead>
              <tbody>

                <tr v-for="extra in vehicle_extras" v-bind:key="extra.id">
                  <td>
                    <input type="checkbox" :id="extra.id" :value="extra.id" v-model="selected_extras">
                  </td>
                  <td>
                    <label :for="extra.id">{{ extra.name }}</label>
                  </td>
                </tr>
              </tbody>
            </table>

            <p style="margin-bottom:0">
              <a @click="update_extras" class="button button-orange button-small">Extras speichern ›</a>
            </p>

          </div>

        </div>
      </div>

    </div>

  </div>
</template>

<script>
import axios from 'axios'
import AdminNav from '../../../../components/admin_nav'

export default {
  name: 'admin_vehicle_category_extras',
  components: {
    AdminNav
  },
  data () {
    return {
      errors: [],
      vehicle_category: {},
      vehicle_extras: [],
      selected_extras: []
    }
  },
  methods: {
    get_data() {

      axios.get(process.env.VUE_APP_BASE_API+'/v1/admin/vehicle_categories/'+this.$route.params.id, { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);
        this.vehicle_category = response.data.vehicle_category;
      })
      .catch(error => {
        console.log(error);
      })

      axios.get(process.env.VUE_APP_BASE_API+'/v1/admin/vehicle_extras', { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);
        this.vehicle_extras = response.data.vehicle_extras;
      })
      .catch(error => {
        console.log(error);
      })

      axios.get(process.env.VUE_APP_BASE_API+'/v1/admin/vehicle_categories/'+this.$route.params.id+'/extras', { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        response.data.vehicle_extras.forEach(value => {
          this.selected_extras.push(value.feature_id)
        });
      })
      .catch(error => {
        console.log(error);
      })

    },
    update_extras() {
      console.log(this.selected_extras);

      axios.post(process.env.VUE_APP_BASE_API+'/v1/admin/vehicle_categories/'+this.$route.params.id+'/extras', {
        'extras': this.selected_extras
      }, { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);
        this.get_data();
        this.$notify({
          group: 'alert',
          type: 'success',
          title: 'Erfolgreich gespeichert.',
        });
      })
      .catch(error => {
        error.response.data.errors.forEach(error => {
          this.$notify({
            group: 'alert',
            type: 'error',
            title: 'Fehler',
            text: error
          });
        });
      })

    }
  },
  mounted () {
    this.get_data();
  }
}
</script>
